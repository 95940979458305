import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Chair15.css'
import starchair1 from '../Images/starchair1.jpg'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Chair15 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Seating"><h1>SEATING</h1></Link>  
            </div>
            
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                {/* <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' /> */}
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span> STAR CHAIR </p>
                      <p className='product-description-para-3'><span className='description-heads'>DESCRIPTION:</span> A wooden chair with back detailing and a handpainted star. The seat is a woven “pinnal” texture.
                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='starchair1' src={starchair1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="arrow-container">
           <Link to="/Chair14"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Chair16"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
        </div>
    </div>
  )
}

export default Chair15